import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Text } from './../Typography';
import { Picture } from 'react-responsive-picture';

export const Container = styled.section`
  @media screen and (max-width: 480px) {
    overflow: hidden;
  }
`;

export const HeroContainer = tw.div`
  w-full
  overflow-x-hidden
`;


const TWBackground = tw.div`
  md:mb-20
`;

export const MaskBottom = styled.div`
  overflow-y: hidden;
`;

export const Background = styled(TWBackground)<{ $customColor: string }>`
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 20px;
  ${({ $customColor }) => ($customColor ? `background-color: ${$customColor};` : '')}
  overflow-x: hidden;
  z-index: -1;
`;

export const ButtonContainer = tw.div`
 relative
 mt-7.5
`;
ButtonContainer.displayName = 'ButtonContainer';

export const StyledButtonContainer = styled(ButtonContainer)`
  height: 50px;
  width: 250px;
  margin-right: auto;

  @media (max-width: 767px) {
    width: 100%;
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Link = styled.a`
  font-size: 0.875rem;
  line-height: 1.25rem;

  @media (max-width: 1023px) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;
Link.displayName = 'Link';

export const SuperText = tw(Text)`
  mb-2.5
  text-corporate-blue
  opacity-70
  pt-0.5
`;
SuperText.displayName = 'SuperText';

export const BannerImageGradientDesktop = styled.div`
  background: rgba(255, 255, 255, 0.4);
`;
export const BannerImageGradientMobile = styled.div`
  background: rgba(255, 255, 255, 0.4);
`;

export const BannerWrapper = tw.div`
  bg-center
  bg-cover
  w-full
  overflow-hidden
  items-center
  flex
  flex-col
  relative
  pt-12
  md:pt-0
  justify-center
  h-heroCarouselInnerTallMob
  md:h-heroCarouselInnerTallBannerHeight
    `;
BannerWrapper.displayName = 'BannerWrapper';


export const ModalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
    height: 2rem;
    width: 2rem;
  }
`;

export const BannerImage = styled(Picture)`
  height: 580px;
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  overflow: hidden;

  @media (max-width: 767px) {
    top: 50%;
    transform: translateY(-50%);
    height: 580px;
  }
`;


export const BannerContentWrapper = tw.div`
  text-left;
`;
BannerContentWrapper.displayName = 'BannerContentWrapper';
