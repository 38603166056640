import React from 'react';
import { CurveMask } from '../CurveMask';
import FadeInSection from '../FadeInSection';
import { BannerImage, BannerContentWrapper, ModalButtonWrapper } from './styles';
import { HeroWrapperContainer } from './CorpVideoHeroWrapperContainer';
import {
  Background,
  BannerImageGradientDesktop,
  BannerImageGradientMobile,
  BannerWrapper,
  HeroContainer,
  Link,
  MaskBottom,
  StyledButtonContainer,
  SuperText,
} from './styles';
import { ICorpVideoHeroProps } from './types';
import { ModalBox } from './../ModalBox';
import { GridRow, Grid, GridItem } from './../Grid';
import { CorporateButton } from './../CorporateButton';
import { Text, Title } from './../Typography';
import { getPictureSources } from '../../lib/utils/helpers';
import { useRouter } from 'next/router';
import { PlayButton } from '../Icons/icons';
import { getCopy } from '../../lib/utils/getCopy';

export const CorpVideoHero: React.FC<ICorpVideoHeroProps> = ({
  bannerImage: image,
  title,
  description,
  superTitle,
  videoId,
  imageAlt,
  copy,
  mobileImage,
}: ICorpVideoHeroProps) => {
  const { locale: market } = useRouter();
  return (
    <HeroWrapperContainer data-test="hero-wrapper-container">

      <Background className="curve-mask-top" $customColor={'rgb(1, 14, 84)'}>
        <MaskBottom className="curve-mask-bottom">
          <HeroContainer data-test="hero-container">
            <BannerWrapper>
              <div
                className={`-z-1 block md:hidden w-full h-full hero-banner-image Tall absolute top-0 left-0`}
                data-test={[`mobile-image-wrapper`]}
              >
                <BannerImageGradientMobile className="w-full h-full" />

                <BannerImage
                  sources={
                    mobileImage?.mobile ? getPictureSources(mobileImage) : getPictureSources(image)
                  }
                  data-test={[`mobile-image`]}
                  alt={imageAlt}
                />
              </div>
              <div
                className="-z-1 hidden md:block w-full h-full hero-desktop-image absolute top-0 left-0"
                data-test={[`desktop-image-wrapper`]}
              >
                <BannerImageGradientMobile className="w-full h-full" />
                <BannerImage
                  sources={getPictureSources(image)}
                  data-test={[`desktop-image`]}
                  alt={imageAlt}
                />
              </div>
              <div style={{ zIndex: 1 }}>
                <Grid>
                  <GridRow columns={16}>
                    <GridItem colStart={2} colSpan={14}>
                      <BannerContentWrapper data-test={[`content-wrapper`]}>
                        <div data-test={[`content`]} className="text-center">
                          {superTitle && (
                            <FadeInSection delay="600ms">
                              <Text
                                className="text-corporate-blue"
                                data-test={[`title`]}
                                tag="p"
                                type="lead"
                              >
                                {superTitle}
                              </Text>
                            </FadeInSection>
                          )}
                          {title && (
                            <FadeInSection delay="600ms">
                              <Title
                                className="text-corporate-blue"
                                data-test={[`title`]}
                                tag="h1"
                                type="lg"
                              >
                                {title}
                              </Title>
                            </FadeInSection>
                          )}
                          {description && (
                            <FadeInSection delay="600ms">
                              <Text
                                className="text-corporate-blue"
                                data-test={[`title`]}
                                tag="p"
                                type="md"
                              >
                                {description}
                              </Text>
                            </FadeInSection>
                          )}
                          <ModalBox videoID={videoId} playButton={<ModalButtonWrapper className="mt-5"><PlayButton className="shrink-0" /><Text className="shrink-0 text-corporate-blue" tag="span" type="lead">{getCopy('watch_video', copy)}</Text></ModalButtonWrapper>} />
                        </div>
                      </BannerContentWrapper>
                    </GridItem>
                  </GridRow>
                </Grid>
              </div>

            </BannerWrapper>
          </HeroContainer>
          <CurveMask />
        </MaskBottom>
      </Background>
    </HeroWrapperContainer>
  );
};
